import { css } from '@emotion/react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import React, { ReactNode, useEffect, useState } from 'react'
import pathsConfig, { PathProps } from '../../../paths-config'
import iconHumbergerCloseUrl from '../../images/charm_menu-hamburger-closr.svg'
import iconHumbergerWhiteUrl from '../../images/charm_menu-hamburger-white.svg'
import iconHumbergerUrl from '../../images/charm_menu-hamburger.svg'
import { mq, rem } from '../../styles/mixin.styles'
import { breakpoints, colors, fontSize, height, mobileSafeSize, space } from '../../styles/variables.styles'
import { generatePath } from '../../utils/customPath'

type GlobalNavProps = {
  children?: ReactNode
  isFooter?: boolean
  type?: 'other' | 'top'
}

/**
 * MenuItem
 *
 * @return menu item tags
 */
const MenuItem = ({ slug, name }: PathProps) => {
  const location = useLocation()
  const currentPath = location.pathname
  const firstDirName = currentPath.split('/')[1]
  const secondDirName = currentPath.split('/')[2]
  const itemPath = generatePath(slug)

  /**
   * isParentCurrent
   * @returns parent dir is current or not
   */
  const isParentCurrent = (): boolean => {
    if (secondDirName && slug === firstDirName) {
      return true
    }
    return false
  }

  return (
    <Link to={itemPath} className={isParentCurrent() ? 'menu__link is-parent-current' : 'menu__link'}>
      <span>{name}</span>
    </Link>
  )
}

/**
 * GlobalNav
 *
 * @param children children
 * @returns global nav
 */
export const GlobalNav = ({ children, isFooter, type = 'other' }: GlobalNavProps) => {
  const [openMenu, setOpenMenu] = useState(false)

  const menuId = 'glbalNavMenu'

  const forHeaderMenu = pathsConfig.filter((output) => {
    return output.onlyFooter === undefined
  })
  const forFooterMenu = pathsConfig.filter((output) => {
    return output.onlyHeader === undefined && output.onlyFooter === undefined
  })

  /**
   * checkOpenState
   * @returns open state
   */
  const checkOpenState = () => {
    if (window.innerWidth > breakpoints.lg) {
      setOpenMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkOpenState)
    return () => {
      window.removeEventListener('resize', checkOpenState)
    }
  }, [])

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [openMenu])

  return (
    <nav css={[navStyles, type === 'top' && topStyles]}>
      <h2 className="sr-only">グローバルナビ</h2>
      {isFooter ? (
        <>
          <ul className="menu--footer">
            {forFooterMenu.map((path, index) => (
              <li key={index} className="menu__item">
                <MenuItem slug={path.slug} name={path.name} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <button
            className="humberger-button"
            aria-expanded={openMenu}
            aria-controls={menuId}
            onClick={() => setOpenMenu(!openMenu)}
          >
            {openMenu ? 'メニューを閉じる' : 'メニューを開く'}
          </button>
          <ul id={menuId} className="menu">
            {forHeaderMenu.map((path, index) => (
              <li key={index} className="menu__item">
                <MenuItem slug={path.slug} name={path.name} />
              </li>
            ))}
          </ul>
        </>
      )}
      {children}
    </nav>
  )
}

const navStyles = css`
  --nav-gap-min: ${rem(17)};
  --nav-gap-max: ${rem(30)};
  --current-bar-height: ${rem(4)};
  --nav-gap-top: ${rem(120)};
  height: 100%;
  .menu {
    transform: translateY(-1200px);
    transition: transform 300ms cubic-bezier(0.85, 0, 0.15, 1);
    display: flex;
    flex-direction: column;
    gap: var(--nav-gap-min);
    width: 100%;
    height: 100vh;
    height: 100dvh;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: var(--nav-gap-top);
    transition: background-color 300ms cubic-bezier(0.85, 0, 0.15, 1);
  }
  button.humberger-button[aria-expanded='true'] ~ .menu {
    transform: translateY(0);
    background-color: ${colors.white.hex};
  }
  .menu__item {
    list-style: none;
    width: 100%;
    min-height: ${rem(mobileSafeSize)};
  }
  // 汎用スタイル
  .menu__link {
    font-size: ${fontSize.md};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    height: 100%;
    min-height: ${rem(mobileSafeSize)};
    color: ${colors.body.hex};
  }
  .menu {
    .menu__link {
      > span {
        padding-bottom: var(--current-bar-height);
        border-bottom-width: var(--current-bar-height);
        border-bottom-style: solid;
        border-bottom-color: transparent;
      }
      &[aria-current='page'],
      &.is-parent-current:not([aria-current='page']),
      &:hover {
        > span {
          border-bottom-color: ${colors.mainBlue.hex};
        }
      }
    }
  }
  .humberger-button {
    position: absolute;
    top: ${rem(8)};
    right: ${rem(11)};
    z-index: 1001;
    color: transparent;
    width: ${rem(mobileSafeSize)};
    height: ${rem(mobileSafeSize)};
    background-image: url(${iconHumbergerUrl});
    background-position: center;
  }
  button.humberger-button[aria-expanded='true'] {
    background-image: url(${iconHumbergerCloseUrl});
  }
  .menu--footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: ${rem(3)};
    .menu__link {
      font-weight: 500;
      justify-content: flex-start;
      &[aria-current='page'],
      &.is-parent-current:not([aria-current='page']),
      &:hover {
        color: ${colors.mainBlue.hex};
      }
    }
  }
  ${mq('lg')} {
    .site-title {
      .header-inner__logo {
        span {
          font-size: ${rem(14)};
        }
      }
    }
    .menu--footer {
      flex-direction: row;
      border-bottom: ${rem(2)} solid ${colors.mainBlue.hex};
      justify-content: space-between;
      .menu__item {
        height: auto;
        width: auto;
        .menu__link {
          height: auto;
          min-height: fit-content;
          padding: ${rem(10)} 0 ${rem(11)} 0;
        }
      }
    }
    .humberger-button {
      display: none;
    }
    .menu {
      transform: none;
      flex-direction: row;
      justify-content: center;
      padding-right: ${space.lg};
      align-items: center;
      position: relative;
      height: 100%;
      padding-top: 0;
      background-color: transparent;
      gap: ${rem(20)};
      .menu__item {
        width: auto;
        height: 100%;
      }
      .menu__link {
        font-size: ${rem(15)};
        > span {
          border: 0;
          padding: 0;
        }
        &::before {
          top: ${rem(-1)};
          content: '';
          display: flex;
          position: absolute;
          width: 1%;
          height: 0;
          transition: all 100ms;
        }
        &[aria-current='page'],
        &.is-parent-current:not([aria-current='page']),
        &:hover {
          &::before {
            width: 100%;
            height: calc(var(--current-bar-height) + ${rem(1)});
            background-color: ${colors.mainBlue.hex};
          }
        }
      }
    }
  }
  ${mq('xl')} {
    .header-inner__logo {
      span {
        font-size: ${rem(16)};
      }
    }
    .menu {
      gap: var(--nav-gap-max);
      .menu__link {
        font-size: ${rem(16)};
      }
    }
  }
`

const topStyles = css`
  .menu {
    .menu__link {
      color: ${colors.white.hex};
      transition: color 300ms cubic-bezier(0.85, 0, 0.15, 1);
      &[aria-current='page'],
      &.is-parent-current:not([aria-current='page']),
      &:hover {
        &::before {
          transition: background-color 300ms cubic-bezier(0.85, 0, 0.15, 1);
        }
      }
    }
  }

  button.humberger-button {
    background-image: url(${iconHumbergerWhiteUrl});
  }

  button.humberger-button[aria-expanded='false'] ~ .menu {
    .menu__link {
      &[aria-current='page'],
      &.is-parent-current:not([aria-current='page']),
      &:hover {
        &::before {
          background-color: ${colors.white.hex};
        }
      }
    }
  }

  button.humberger-button[aria-expanded='true'] ~ .menu {
    background-color: rgba(${colors.white.rgb}, 1);
    .menu__link {
      color: rgba(${colors.body.rgb}, 1);
    }
  }
  ${mq('lg')} {
    height: ${rem(height.headerMax)};
    position: fixed;
  }
`
